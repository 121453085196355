





















import { Vue, Component } from 'vue-property-decorator'
// @ts-ignore
import { QrInput } from '@c4tplatform/vue_components'

@Component({
    components: {
        QrInput,
    },
})
export default class PrivateKey extends Vue {
    privatekey: string = ''
    isLoading: boolean = false
    inputType = 'input'
    error: string = ''
    async mounted() {
        if (!(window.getComputedStyle(this.$el) as any).webkitTextSecurity) {
            this.inputType = 'password'
        }
    }
    async access() {
        if (!this.canSubmit || this.isLoading) return
        this.error = ''
        this.isLoading = true
        let key = this.privatekey

        try {
            await this.$store.dispatch('accessWalletSingleton', key)
            this.onsuccess()
        } catch (e) {
            this.onerror('Invalid Private Key.')
        }
    }
    onsuccess() {
        this.isLoading = false
        this.privatekey = ''
    }
    onerror(e: any) {
        this.error = e
        this.privatekey = ''
        this.isLoading = false
    }
    get canSubmit(): boolean {
        if (!this.privatekey) {
            return false
        }
        return true
    }
}
