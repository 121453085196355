import { render, staticRenderFns } from "./CrossChain.vue?vue&type=template&id=520b973c&scoped=true&"
import script from "./CrossChain.vue?vue&type=script&lang=ts&"
export * from "./CrossChain.vue?vue&type=script&lang=ts&"
import style0 from "./CrossChain.vue?vue&type=style&index=0&id=520b973c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "520b973c",
  null
  
)

export default component.exports