


































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import Big from 'big.js'

import Spinner from '@/components/misc/Spinner.vue'
import DepositOffers from '@/components/wallet/earn/DepositOffers.vue'
import UserRewards from '@/components/wallet/earn/UserRewards.vue'
import { bnToBig } from '@/helpers/helper'

import { BN } from '@c4tplatform/caminojs/dist'

@Component({
    name: 'earn',
    components: {
        DepositOffers,
        Spinner,
        UserRewards,
    },
})
export default class Earn extends Vue {
    loadingRefreshDepositRewards = false
    pageNow: any = null
    subtitle: string = ''
    intervalID: any = null
    tab: string = 'actine_earning'

    transfer() {
        this.$router.replace('/wallet/cross_chain')
    }

    viewRewards() {
        this.pageNow = UserRewards
        this.subtitle = this.$t('earn.subtitle4') as string
    }
    cancel() {
        this.pageNow = null
        this.subtitle = ''
    }

    deactivated() {
        this.cancel()
    }

    destroyed() {
        clearInterval(this.intervalID)
    }

    refresh() {
        this.loadingRefreshDepositRewards = true
        this.$store.dispatch('Platform/updateRewards').then(
            () => {
                this.loadingRefreshDepositRewards = false
            },
            () => {
                this.loadingRefreshDepositRewards = false
            }
        )
    }

    get platformUnlocked(): BN {
        return this.$store.getters['Assets/walletPlatformBalance']
    }

    get platformLockedStakeable(): BN {
        // return this.$store.getters.walletPlatformBalanceLockedStakeable
        return this.$store.getters['Assets/walletPlatformBalanceLockedStakeable']
    }

    get totBal(): BN {
        return this.platformUnlocked.add(this.platformLockedStakeable)
    }

    get pNoBalance() {
        return this.platformUnlocked.add(this.platformLockedStakeable).isZero()
    }

    get minStakeAmt(): Big {
        let bn = this.$store.state.Platform.minStake
        return bnToBig(bn, 9)
    }
}
