










































import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'
import MyKeys from '@/components/wallet/manage/MyKeys.vue'
import ImportKeys from '@/components/modals/ImportKeys.vue'
import ExportKeys from '@/components/modals/ExportKeys.vue'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import SaveAccountModal from '@/components/modals/SaveAccount/SaveAccountModal.vue'

import { WalletNameType } from '@/js/wallets/types'
import AccountSettingsModal from '@/components/modals/AccountSettings/AccountSettingsModal.vue'

@Component({
    name: 'manage',
    components: {
        AccountSettingsModal,
        MyKeys,
        ImportKeys,
        ExportKeys,
        SaveAccountModal,
    },
})
export default class ManageKeys extends Vue {
    $refs!: {
        import: ImportKeys
        export: ExportKeys
        account_modal: SaveAccountModal
        account_settings: AccountSettingsModal
    }

    get account() {
        return this.$store.getters['Accounts/account']
    }

    importKeys() {
        this.$refs.import.open()
    }

    exportKeys() {
        this.$refs.export.open()
    }

    openSaveAccount() {
        this.$refs.account_modal.open()
    }

    openAccountSettings() {
        this.$refs.account_settings.open()
    }

    get canEncryptWallet() {
        return ['mnemonic', 'singleton', 'multisig'].includes(this.walletType)
    }

    get walletType(): WalletNameType {
        return this.$store.state.activeWallet.type
    }

    get hasVolatile() {
        return this.$store.getters.accountChanged
    }

    get allWallets(): MnemonicWallet[] {
        return this.$store.state.wallets
    }

    get warnUpdateKeyfile() {
        return this.$store.state.warnUpdateKeyfile
    }
}
