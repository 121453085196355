





























import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import MnemonicDisplay from '@/components/misc/MnemonicDisplay.vue'
import * as bip39 from 'bip39'
import MnemonicInput from '@/components/misc/MnemonicInput.vue'

@Component({
    components: {
        MnemonicInput,
        MnemonicDisplay,
    },
})
export default class Mnemonic extends Vue {
    phrase: string = ''
    isLoading: boolean = false
    err: string = ''

    beforeDestroy() {
        this.phrase = ''
    }

    mnemonicUpdate(ev: any) {
        if (ev.index < 0) {
            this.phrase = ev.value ?? ''
        }
        const phraseArray = this.phrase.split(' ')
        phraseArray[ev.index] = ev.value ?? ''
        this.phrase = phraseArray.join(' ')
    }

    mnemonicReplace(ev: any) {
        this.phrase = ev.value
    }

    errCheck() {
        let words = this.phrase.split(' ')

        // not a valid key phrase
        if (words.length !== 24) {
            this.err = `${this.$t('access.mnemonic.error')}`
            return false
        }

        let isValid = bip39.validateMnemonic(this.phrase)
        if (!isValid) {
            this.err = 'Invalid mnemonic phrase. Please check your phrase.'
            return false
        }

        this.err = ''
        return true
    }

    get wordCount(): number {
        return this.phrase.trim().split(' ').length
    }

    get canSubmit() {
        return this.wordCount === 24 && this.errCheck()
    }

    async access() {
        this.phrase = this.phrase.trim()
        let phrase = this.phrase

        this.isLoading = true

        if (!this.errCheck()) {
            this.isLoading = false
            return
        }

        setTimeout(async () => {
            try {
                await this.$store.dispatch('accessWallet', phrase)
                document.body.scrollTo(0, 0)
                this.isLoading = false
            } catch (e) {
                this.isLoading = false
                this.err = `${this.$t('access.mnemonic.error')}`
            }
        }, 500)
    }
}
