







import { Vue, Component } from 'vue-property-decorator'
import ToSContent from '@/components/misc/ToSContent.vue'
@Component({
    components: { ToSContent },
})
export default class Legal extends Vue {}
