


































import 'reflect-metadata'
import { Component, Vue, Watch } from 'vue-property-decorator'
import AddValidator from '@/components/wallet/earn/Validate/AddValidator.vue'
import { BN } from '@c4tplatform/caminojs/dist'
import { bnToBig } from '@/helpers/helper'
import Big from 'big.js'
import { WalletHelper } from '@/helpers/wallet_helper'
import RegisterNode from '@/components/wallet/earn/Validate/RegisterNode.vue'
import { AddressState } from '@c4tplatform/caminojs/dist/apis/platformvm/addressstatetx'
import { WalletCore } from '@/js/wallets/WalletCore'
import { ava } from '@/AVA'
import { AvaNetwork } from '@/js/AvaNetwork'
import { OneBN } from '@/constants'

@Component({
    name: 'validator',
    components: {
        RegisterNode,
        AddValidator,
    },
})
export default class Validator extends Vue {
    isKycVerified = false
    isConsortiumMember = false
    isSuspended = false
    registeredNodeID = ''
    intervalID: any = null
    loading = 0

    async updateValidators() {
        await this.$store.dispatch('Platform/updateValidators')
    }

    activated() {
        this.loading = 1
        this.updateValidators().then(() => this.evaluateCanRegisterNode())
    }

    deactivated() {
        clearInterval(this.intervalID)
    }

    nodeRegistered(nodeId: string) {
        this.registeredNodeID = nodeId
    }

    @Watch('$store.state.network')
    @Watch('$store.state.activeWallet')
    evaluateCanRegisterNode() {
        const p1 = WalletHelper.getAddressState(this.staticAddress).then((result) => {
            this.isKycVerified = !result.and(OneBN.shln(AddressState.KYC_VERIFIED)).isZero()
            this.isConsortiumMember = !result.and(OneBN.shln(AddressState.CONSORTIUM)).isZero()
            this.isSuspended = !result.and(OneBN.shln(AddressState.NODE_DEFERRED)).isZero()
        })
        const p2 = WalletHelper.getRegisteredNode(this.staticAddress).then(
            (nodeID) => (this.registeredNodeID = nodeID),
            () => (this.registeredNodeID = '')
        )
        Promise.all([p1, p2]).then(
            () => (this.loading &= ~1),
            () => (this.loading &= ~1)
        )
    }

    @Watch('network')
    onInitNetworkChange() {
        this.loading = 3
    }

    @Watch('assetLoading')
    onAssetLoading(current: boolean) {
        if (!current) setTimeout(() => (this.loading &= ~2), 500)
    }

    get hasEnoughLockablePlatformBalance(): boolean {
        return this.totBal.gte(this.minPlatformUnlocked)
    }

    get staticAddress() {
        return (this.$store.state.activeWallet as WalletCore).getStaticAddress('P')
    }

    get platformUnlocked(): BN {
        if (this.depositAndBond) return this.$store.getters['Assets/walletPlatformBalanceUnlocked']
        else return this.$store.getters['Assets/walletPlatformBalance']
    }

    get minPlatformUnlocked(): BN {
        return ava.getNetwork().P.minStake
    }

    get depositAndBond(): boolean {
        return this.$store.getters['Network/depositAndBond']
    }

    get platformLockedStakeable(): BN {
        if (this.depositAndBond) return this.$store.getters['Assets/walletPlatformBalanceDeposited']
        return this.$store.getters['Assets/walletPlatformBalanceLockedStakeable']
    }

    get totBal(): BN {
        return this.platformUnlocked.add(this.platformLockedStakeable)
    }

    get pNoBalance() {
        return this.totBal.isZero()
    }

    get canValidate(): boolean {
        return this.totBal.gte(ava.getNetwork().P.minStake)
    }

    get minStakeAmt(): Big {
        return bnToBig(ava.getNetwork().P.minStake, 9)
    }

    get hasValidator(): boolean {
        return this.registeredNodeID === ''
            ? false
            : this.$store.getters['Platform/isValidator'](this.registeredNodeID)
    }

    get hasRegisteredNodeID(): boolean {
        return this.registeredNodeID !== ''
    }

    get network(): AvaNetwork {
        return this.$store.state.Network.selectedNetwork
    }

    get assetLoading(): boolean {
        return this.$store.state.Assets.balanceLoading
    }
}
