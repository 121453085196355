
























import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Launch extends Vue {
    name = 'launch'
    error = ''
    url = ''

    async submit() {
        await this.$store.dispatch('Launch/launchItem', this.url)
    }
}
