
























import 'reflect-metadata'
import { Vue, Component } from 'vue-property-decorator'

import BulkCard from '@/components/wallet/transfer/BulkTransfer.vue'
import TransferCard from '@/components/wallet/transfer/Transfer.vue'

@Component({
    components: {
        BulkCard,
        TransferCard,
    },
})
export default class Transfer extends Vue {
    tab: string = 'transfer'

    get networkStatus(): string {
        let stat = this.$store.state.Network.status
        return stat
    }
}
